import React, { Fragment, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../utils/cookies.config";
import { PartnerContext } from "../utils/UserContext";


const PrivateRoute = ({ component: Component, path, name, exact }) => {
  const user = useContext(PartnerContext);

  // const location = useHistory();

 

  return (
    <Fragment>
      {/* <Redirect from={"/"} to={"/dashboard"} /> */}
      <Route
        path={path}
        exact={exact}
        name={name}
        render={(props) => {
          let token = Auth.getCipher();
       
          if (!token) {
            return <Redirect to={{ pathname: "/login" }} />;
          }
          //  else if (token && !user?.fullname) {
          //   return <Redirect to={{ pathname: "/login" }} />;
          // }
          return <Component />;
        }}
      />
    </Fragment>
  );
};

export default PrivateRoute;
