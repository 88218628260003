import Cookies from "js-cookie";

export const BASE_URL =
  process.env.REACT_APP_SERVER_URL ||
  // "http://localhost:8068"
  "https://api.hyper.itmsgh.com";

export const BASE_URL2 =
  process.env.REACT_APP_SERVER_URL ||
  // "http://localhost:3030"
  "https://api.mmt.itmsgh.com";

export const VIP_URL = "https://api.vip.tapngogh.com";

class Auth {
  getCipher() {
    return sessionStorage.getItem("/t") || null;
  }

  setCipher(token, data = {}) {
    sessionStorage.setItem("/t", token);
    sessionStorage.setItem("/r", data?.role);
    // sessionStorage
    // Cookies.set("/t", token);
    // Cookies.set("/r", data?.role);
    sessionStorage.setItem("data", JSON.stringify(data));
  }

  clearCipher() {
    Cookies.remove("/t");
    sessionStorage.clear();
  }
}

export default new Auth();
