import React, { useEffect, useState } from "react";
import { PartnerContext } from "./UserContext";
import { useHistory } from "react-router-dom";

export default function UserContextProvider(props) {
  const [userData, setUserData] = useState({});
  const value = { ...userData, setUserData };

  // const {push } = useHistory();

  useEffect(() => {
    getUserData();
  }, [props]);

  async function getUserData() {
    const nUserData = sessionStorage.getItem("data");
    // if (!nUserData) {
    //   // ppush("#/login");
    // }
    userData && setUserData(JSON.parse(nUserData));
  }

  return (
    <PartnerContext.Provider value={value}>
      {props.children}
    </PartnerContext.Provider>
  );
}
