import React, { Fragment } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { Grid, Hourglass, Ripple } from "react-spinners-css";

TopBarProgress.config({
  barColors: {
    0: "green",
    "1.0": "green",
  },
  shadowBlur: 5,
});

export function TopBarLoader() {
  return (
    <React.Fragment>
      <TopBarProgress color="red" />
    </React.Fragment>
  );
}

export function PageLoader() {
  return (
    <Fragment>
      <div className={"w-full h-screen flex justify-center items-center"}>
        <Grid size={100} />
      </div>
    </Fragment>
  );
}
export function HourglassLoader() {
  return (
    <Fragment>
      <div
        className="w-full my-20"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "40vh",
        }}
      >
        <Ripple size={80} color="red" />
      </div>
    </Fragment>
  );
}
