import { Tabs } from "antd";
import { ChevronRightIcon } from "evergreen-ui";
import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import OrganizationOverview from "./Tabs/Overview";
import SalesMerchants from "./Tabs/Merchants";

function SalesAgentDetails({ match, location }) {
  const { id } = match?.params;
  console.log(id);

  const [companyDetaills, setCompanyDetails] = useState();

  async function getSalesOrganization() {
    const nOrgs = JSON.parse(sessionStorage.getItem("agents"));
    const nCompany = nOrgs.find((item) => item._id === id);
    if (nCompany) setCompanyDetails(nCompany);
  }

  useEffect(() => {
    getSalesOrganization();
  }, []);

  const TransactionsTabs = [
    {
      key: "2",
      label: "Overview",
      children: <OrganizationOverview id={id} />,
    },
    {
      key: "1",
      label: "Merchants",
      children: <SalesMerchants id={id} />,
    },
  ];

  return (
    <div>
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex">
              <a
                href="#/agents"
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Sales Agents
              </a>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <p
                href="#"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {companyDetaills?.name}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {companyDetaills?.name}
      </h2>
      <div className="bg-white p-5 h-screen mt-5 rounded-md">
        <Tabs defaultActiveKey="2" items={TransactionsTabs} />
      </div>
    </div>
  );
}

export default withRouter(SalesAgentDetails);
