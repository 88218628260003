import React, { Fragment, useState, useEffect } from "react";
import useRequest from "../../../utils/Request";
import { HourglassLoader } from "../../../components/Loaders";
import {
  toaster,
  SideSheet,
  Pane,
  Heading,
  TextInputField,
  SearchInput,
  Avatar,
} from "evergreen-ui";
import axios from "axios";
import Money from "../../../utils/Money";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../utils/cookies.config";
import Button from "../../../components/Button";

export default function SalesMerchants({ id }) {
  const [showSideBar, setShowSideBar] = useState(false);
  const [username, setUsername] = useState(true);
  const [searching, setSearching] = useState(false);

  const [currentItem, setCurrentItem] = useState();
  const [showDepositSideSheet, setDepositSideSheet] = useState(false);

  const [getMerchants, { loading, data, error }] = useRequest(
    { api: 1, path: "/organisation/merchants/all" },
    {
      onCompleted: (d) => {
        console.log(d);
      },
      onError: (e) => {
        toaster.danger(e.response.data);
      },
    }
  );

  const [createMerchant, { loading: creatingMerchant }] = useRequest(
    { api: 1, path: "/organisation/merchant/create", method: "POST" },
    {
      onCompleted: (d) => {
        // console.log(d);
        setShowSideBar(false);
        toaster.success("Merchant created successfully");
        getMerchants({ organisation: id });
      },
      onError: (e) => {
        toaster.danger(e.response.data);
      },
    }
  );

  const [depositCash, { loading: depositingCash }] = useRequest(
    { api: 2, path: "/merchant/deposit/funds", method: "POST" },
    {
      onCompleted: (d) => {
        toaster.success("Merchant deposit successfully");
        getMerchants({ organisation: id });
        setDepositSideSheet(false);
      },
      onError: (e) => {
        toaster.danger(e.response.data);
      },
    }
  );

  async function checkUsername(t) {
    try {
      setSearching(true);
      const isPresent = await axios.get(
        BASE_URL + "/auth/user/checkMerchantUsername?username=" + t
      );
      setUsername(isPresent?.data);
      setSearching(false);
    } catch (e) {
      setSearching(false);
      toaster.warning(e.message);
    }
  }

  useEffect(() => {
    getMerchants({ organisation: id });
  }, []);

  // console.log("Data", data);

  return (
    <Fragment>
      <SideSheet
        onCloseComplete={() => {
          setUsername(true);
          setShowSideBar(false);
        }}
        isShown={showSideBar}
        containerProps={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
        }}
      >
        <div class="py-6 px-4 bg-red-700 sm:px-6">
          <div class="flex items-center justify-between">
            <h2 class="text-lg font-bold text-white" id="slide-over-title">
              New Merchant
            </h2>
          </div>
          <div class="mt-1">
            <p class="text-sm text-white">
              Get started by filling in the information below to create your new
              merchant.
            </p>
          </div>
        </div>
        <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
          <form
            onSubmit={(t) => {
              t.preventDefault();

              if (username) {
                toaster.danger(
                  "Invalid username, please choose another username"
                );
                return;
              }

              const payload = {
                firstname: t.target.firstname.value,
                lastname: t.target.lastname.value,
                email: t.target.email.value,
                address: t.target.address.value,
                password: t.target.password.value,
                phonenumber: t.target.phonenumber.value,
                username: t.target.username.value,
                organisation: id,
              };

              createMerchant(payload);
            }}
          >
            <div className="md:flex space-x-4">
              <div className="flex-1">
                <div className="mt-1">
                  <TextInputField
                    required
                    isRequired
                    name="lastname"
                    label="Lastname"
                    placeholder="Enter first name here"
                  />
                </div>
              </div>
              <div className="flex-1">
                <div class="mt-1">
                  <TextInputField
                    isRequired
                    name="firstname"
                    required
                    label="Other names"
                    placeholder="Enter other names here"
                  />
                </div>
              </div>
            </div>
            <div className="md:flex space-x-4 ">
              <div className="flex-1">
                <div class="mt-1">
                  <TextInputField
                    required
                    minLength={10}
                    maxLength={10}
                    isRequired
                    name="phonenumber"
                    label="Phone number"
                    type="number"
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
              <div className="flex-1">
                <div class="mt-1">
                  <TextInputField
                    required
                    name="email"
                    isRequired
                    label="Email"
                    placeholder="Enter merchant email here"
                  />
                </div>
              </div>
            </div>
            <div className="md:flex space-x-4 ">
              <div className="flex-1">
                <div class="mt-1">
                  <TextInputField
                    required
                    name="address"
                    isRequired
                    label="Address"
                    placeholder="Enter merchant address here"
                  />
                </div>
              </div>
              <div className="flex-1">
                <h1>Username</h1>
                <div class="mt-1">
                  <SearchInput
                    label="Default text input field"
                    name="username"
                    hint="This is a hint."
                    textTransform="lowercase"
                    onChange={(t) => {
                      if (
                        t.target.value
                          .trim()
                          .match(
                            /^(?=[a-z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
                          )
                      ) {
                        checkUsername(t.target.value);
                      } else {
                        setUsername(true);
                      }
                    }}
                    placeholder="Search for available username"
                  />
                </div>
                {searching ? (
                  <p>Loading...</p>
                ) : username ? (
                  <p className="text-sm text-red-500">Username not available</p>
                ) : (
                  <p className="text-sm text-green-500">Username available</p>
                )}
              </div>
            </div>
            <div className="flex-1">
              <div class="mt-1">
                <TextInputField
                  required
                  isRequired
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Enter merchant password"
                />
              </div>
            </div>

            {/* <a
              target="_blank"
              href="https://apps.apple.com/la/app/hyperpay-manage-your-cards/id1550301349"
              type="button"
              class="inline-flex items-center px-4 py-2 border m-2 border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-black hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              App store
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.itmsgh.hyper"
              type="button"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-black hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Google Play Store
            </a>
            <p className="text-sm text-gray-700">
              Merchant should download Hyper App from the App store or Play
              Store to manage their account. They'll be required to reset their
              password and pass code.
            </p> */}

            <div class="my-10">
              <Button
                loading={creatingMerchant}
                title="Create Merchant"
                type="submit"
                class="inline-flex items-center px-4 my-10 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-yellow-700 hover:bg-yellow-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              />
            </div>
          </form>
        </Pane>
      </SideSheet>
      <SideSheet
        onCloseComplete={() => {
          setDepositSideSheet(false);
        }}
        containerProps={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
        }}
        isShown={showDepositSideSheet}
      >
        <div class="py-6 px-4 bg-red-700 sm:px-6">
          <div class="flex items-center justify-between">
            <h2 class="text-lg font-medium text-white" id="slide-over-title">
              Credit Merchant account with Tap and Go Credit
            </h2>
          </div>
          <div class="mt-1">
            <p class="text-sm text-gray-200">
              This allows merchant to resell Tap and Go to cards
            </p>
          </div>
        </div>
        <section aria-labelledby="profile-overview-title">
          <div class=" bg-white overflow-hidden shadow">
            <h2 class="sr-only" id="profile-overview-title">
              Profile Overview
            </h2>
            <div class="bg-white p-6">
              <div class="sm:flex sm:items-center sm:justify-between">
                <div class="sm:flex sm:space-x-5">
                  <div class="flex-shrink-0">
                    <Avatar name={currentItem?.firstname} size={70} />
                  </div>
                  <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                      {currentItem?.firstname} {currentItem?.lastname}
                    </p>
                    <p class="text-sm font-medium text-gray-600">
                      {currentItem?.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
              <div class="px-6 py-5 text-sm font-medium text-center">
                <span class="text-gray-600"> {currentItem?.phonenumber}</span>
              </div>

              <div class="px-6 py-5 text-sm font-medium text-center">
                <span class="text-gray-600">
                  &cent;{Money(currentItem?.account?.balance)}
                </span>
              </div>
            </div>
          </div>
        </section>
        <div className="p-10 bg-primary">
          <form
            onSubmit={(t) => {
              t.preventDefault();

              if (t?.target.amount.value !== t?.target.amountRepeat.value) {
                toaster.danger(
                  "Please check amount, amount doesn't match repeated amount"
                );
                return;
              }

              const payload = {
                receiver: currentItem?.username,
                amount: Number(t.target.amount.value),
                password: t.target.password.value,
              };

              depositCash(payload);
            }}
          >
            <TextInputField
              required
              isRequired
              name="amount"
              label="Amount"
              placeholder="Enter Amount here"
            />
            <TextInputField
              required
              isRequired
              name="amountRepeat"
              label="Repeat Amount"
              placeholder="Please repeat amount here"
            />
            <TextInputField
              required
              isRequired
              name="password"
              type="password"
              label="Password"
              placeholder="Enter your password here"
            />
            <div class="my-10">
              <Button
                loading={depositingCash}
                title="Submit"
                type="submit"
                class="inline-flex items-center px-4 my-10 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-blue-700 hover:bg-blue-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              />
            </div>
          </form>
        </div>
      </SideSheet>
      <div class="">
        <div class="md:flex lg:flex lg:items-center lg:justify-between">
          <div class="flex-1 min-w-0">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Merchants
            </h2>
          </div>
          <div class="mt-5 flex lg:mt-0 lg:ml-4">
            <span class="sm:ml-3">
              <button
                onClick={() => setShowSideBar(true)}
                type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <svg
                  class="-ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                Add Merchant
              </button>
            </span>
          </div>
        </div>

        <div class="flex flex-col py-5">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {loading ? (
                  <HourglassLoader />
                ) : (
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Details
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-Fxs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          E-Cash
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Pre-paid Balance
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      {data?.map((item) => (
                        <tr key={item?._id}>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                              <div class="flex-shrink-0 h-10 w-10">
                                <Avatar
                                  size={40}
                                  name={item?.firstname + " " + item?.lastname}
                                />
                              </div>
                              <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                  {item?.firstname} {item?.lastname}
                                </div>
                                <div class="text-sm text-gray-500">
                                  {item?.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                              {item?.phonenumber}
                            </div>
                            <div class="text-sm text-gray-500">
                              @{item?.username} &bull; {item?.address}
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span
                              class={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${
                                item?.verified ? "green" : "red"
                              }-100 text-${
                                item?.verified ? "green" : "red"
                              }-800`}
                            >
                              {item?.verified ? "Verified" : "Not Verified"}
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            GHS{Money(item?.account?.balance)}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            GHS {Money(item?.account?.paymentBalance)}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            {item?.role === "admin" && (
                              <a
                                onClick={() => {
                                  setCurrentItem(item);
                                  setDepositSideSheet(true);
                                }}
                                class="text-red-600 hover:text-red-900"
                              >
                                Deposit E-Cash
                              </a>
                            )}
                            <Link
                              to={`/merchants/details/${item?.username}?_id=${item?._id}`}
                              class="text-red-600 ml-5 hover:text-red-900"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
