import React, { Fragment, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/tapngo.png";
import cookiesConfig from "../../utils/cookies.config";
import Transitions from "../../utils/Transitions";
import { PartnerContext } from "../../utils/UserContext";

export default function AppHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const user = useContext(PartnerContext);

  return (
    <Fragment>
      <nav class="bg-white shadow-md">
        <div class="max-w-8xl mx-auto px-2 sm:px-4 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex px-2 lg:px-0">
              <div class="flex-shrink-0 flex items-center">
                <img
                  class="block lg:hidden h-8 w-auto"
                  src={Logo}
                  alt="hyper-logo"
                />
                <img
                  class="hidden lg:block h-8 w-auto"
                  src={Logo}
                  alt="Workflow"
                />
              </div>
              <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
                <a
                  href="#/dashboard"
                  class={`${
                    pathname === "/dashboard"
                      ? "border-red-500"
                      : "border-transparent"
                  }  ${
                    pathname === "/dashboard"
                      ? "text-gray-900"
                      : "text-gray-500"
                  } hover:border-gray-300 hover:text-gray-700  inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  Dashboard
                </a>
                {(user?.role === "admin" ||
                  user?.role === "admin-hq" ||
                  user?.role == "finance" ||
                  user?.role == "operations") && (
                  <a
                    href="#/merchants"
                    class={`${
                      pathname.includes("merchants")
                        ? "border-red-500"
                        : "border-transparent"
                    }  ${
                      pathname === "/merchants"
                        ? "text-gray-900"
                        : "text-gray-500"
                    }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Merchants
                  </a>
                )}
                <a
                  href="#/subscriptions"
                  class={`${
                    pathname === "/subscriptions"
                      ? "border-red-500"
                      : "border-transparent"
                  }  ${
                    pathname === "/subscriptions"
                      ? "text-gray-900"
                      : "text-gray-500"
                  }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  Subscriptions
                </a>
                <a
                  href="#/transactions"
                  class={`${
                    pathname === "/transactions"
                      ? "border-red-500"
                      : "border-transparent"
                  }  ${
                    pathname === "/transactions"
                      ? "text-gray-900"
                      : "text-gray-500"
                  }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  Transactions
                </a>
                {user?.role === "admin" && (
                  <a
                    href="#/merchant-deposits"
                    class={`${
                      pathname === "/merchant-deposits"
                        ? "border-red-500"
                        : "border-transparent"
                    }  ${
                      pathname === "/merchant-deposits"
                        ? "text-gray-900"
                        : "text-gray-500"
                    }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Merchants Deposits
                  </a>
                )}
                {user?.role === "admin" && (
                  <a
                    href="#/tills"
                    class={`${
                      pathname === "/tills"
                        ? "border-red-500"
                        : "border-transparent"
                    }  ${
                      pathname === "/tills" ? "text-gray-900" : "text-gray-500"
                    }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Tills
                  </a>
                )}
                {(user?.role === "admin" ||
                  user?.role == "finance" ||
                  user?.role === "hq-admin" ||
                  user?.role === "admin-hq" ||
                  user?.role === "audit") &&
                  !user?.depot && (
                    <>
                      <a
                        href="#/payouts"
                        class={`${
                          pathname === "/payouts"
                            ? "border-red-500"
                            : "border-transparent"
                        }  ${
                          pathname === "/payouts"
                            ? "text-gray-900"
                            : "text-gray-500"
                        }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                      >
                        Payouts
                      </a>
                      <a
                        href="#/balance-sheets"
                        class={`${
                          pathname === "/balance-sheets"
                            ? "border-red-500"
                            : "border-transparent"
                        }  ${
                          pathname === "/balance-sheets"
                            ? "text-gray-900"
                            : "text-gray-500"
                        }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                      >
                        Balance Sheets
                      </a>
                    </>
                  )}
                {user?.username === "0548215803" && (
                  <a
                    href="#/agents"
                    class={`${
                      pathname.includes("agents")
                        ? "border-red-500"
                        : "border-transparent"
                    }  ${
                      pathname.includes("agents")
                        ? "text-gray-900"
                        : "text-gray-500"
                    }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Sales Agents
                  </a>
                )}

                {user?.username === "0548215803" && (
                  <a
                    href="#/conductor-payments"
                    class={`${
                      pathname.includes("conductor-payments")
                        ? "border-red-500"
                        : "border-transparent"
                    }  ${
                      pathname.includes("conductor-payments")
                        ? "text-gray-900"
                        : "text-gray-500"
                    }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    VIP Services
                  </a>
                )}
              </div>
            </div>

            <div class="flex items-center lg:hidden">
              <button
                type="button"
                onClick={() => {
                  setMobileMenu(!mobileMenu);
                }}
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  class="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  class="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="hidden lg:ml-4 lg:flex lg:items-center">
              <a
                href="#/developer"
                class={`${
                  pathname === "/developer"
                    ? "border-red-500"
                    : "border-transparent"
                }  ${
                  pathname === "/developer" ? "text-gray-900" : "text-gray-500"
                }  hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-2 pt-1 border-b-2 text-sm font-medium`}
              >
                Developer
              </a>
              <a
                href="#/settings/general"
                class={`${
                  pathname === "/settings/general"
                    ? "border-red-500"
                    : "border-transparent"
                }  ${
                  pathname === "/settings/general"
                    ? "text-gray-900"
                    : "text-gray-500"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </a>

              <div class="ml-4 relative flex-shrink-0">
                <div>
                  <button
                    type="button"
                    class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="user-menu-button"
                    aria-expanded="false"
                    onClick={() => setShowMenu(!showMenu)}
                    aria-haspopup="true"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      class="h-8 w-8 rounded-full"
                      src="https://www.stevefarber.com/wp-content/uploads/2019/01/man-avatar-placeholder.png"
                      alt=""
                    />
                  </button>
                </div>

                {/* <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          --> */}
                <Transitions
                  show={showMenu}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabindex="-1"
                  >
                    <span
                      onClick={() => {
                        setShowMenu(false);
                        cookiesConfig.clearCipher();
                        push("/login");
                      }}
                      //   href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-2"
                    >
                      Sign out
                    </span>
                  </div>
                </Transitions>
              </div>
            </div>
          </div>
        </div>

        <Transitions
          show={mobileMenu}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div class="lg:hidden" id="mobile-menu">
            <div class="pt-2 pb-3 space-y-1">
              <a
                href="#"
                class="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Dashboard
              </a>
              <a
                href="#"
                class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Team
              </a>
              {/* <a
                href="#"
                class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Loyalty Cards
              </a> */}
              <a
                href="#"
                class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Transactions
              </a>
            </div>
            <div class="pt-4 pb-3 border-t border-gray-200">
              <div class="flex items-center px-4">
                <div class="flex-shrink-0">
                  <img
                    class="h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=ah3lxr8uqw&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="text-base font-medium text-gray-800">
                    Tom Cook
                  </div>
                  <div class="text-sm font-medium text-gray-500">
                    tom@example.com
                  </div>
                </div>
                <button class="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">View notifications</span>

                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>
              </div>
              <div class="mt-3 space-y-1">
                <a
                  href="#"
                  class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Your Profile
                </a>
                <a
                  href="#"
                  class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Settings
                </a>
                <a
                  href="#"
                  class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Sign out
                </a>
              </div>
            </div>
          </div>
        </Transitions>
      </nav>
    </Fragment>
  );
}
