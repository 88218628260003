import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { TopBarLoader } from "../components/Loaders";
import Login from "../screens/Login";
import Layout from "../shared/Layout";
import { useEffect } from "react";


function RootNavigator() {
  return (
    <BrowserRouter basename="/#">
      <Suspense fallback={TopBarLoader()}>
        <Switch>
          <Route key={1} component={Login} path={"/login"} />
          <Route key={2} component={Layout} path={"/"} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default function AppNavigator() {
  return <RootNavigator />;
}
