import AppNavigator from "./navigation/AppNavigator";
import UserContextProvider from "./utils/UserContextProvider";

function App() {
  return (
    <UserContextProvider>
      <AppNavigator />
    </UserContextProvider>
  );
}

export default App;
