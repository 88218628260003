import { toaster } from "evergreen-ui";
import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { Input, Form, notification } from "antd";
import cookiesConfig, { BASE_URL2 } from "../../utils/cookies.config";
import Logo from "../../assets/tapngo.png";
import makeRequest from "../../utils/Request";
import axios from "axios";
import { PartnerContext } from "../../utils/UserContext";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

export default function Login() {
  const user = useContext(PartnerContext);
  const [showOtp, setShowOTP] = useState();
  const [phone, setPhone] = useState();
  const [loadingg, setLoading] = useState(false);
  const { push } = useHistory();
  const [username, setUsername] = useState();

  const [loginRequest, { loading }] = makeRequest(
    { api: 2, path: "/auth/login", method: "POST" },
    {
      onCompleted: (d) => {
        cookiesConfig.setCipher(d?.data?.token, d?.data);
        user.setUserData(d?.data);
        push("/dashboard");
      },
      onError: (e) => {
        // console.log(e.response.data)
        toaster.danger(e?.response?.data.User || e?.message.toString());
      },
    }
  );

  async function handleLogin(e) {
    // e.preventDefault();
    // console.log(e.username);

    if (!showOtp) {
      // this.setState({ loading: true });
      setLoading(true);
      try {
        const n = await axios.post(`${BASE_URL2}/auth/request-otp`, {
          username: e.username,
          msisdn: "TFG",
        });

        setUsername(e.username);
        setPhone(n.data);
        setShowOTP(true);
        setLoading(false);
      } catch (e) {
        notification.error({
          message: "Wrong username",
          description: "Your username is incorrect",
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      const payload = {
        username: username,
        password: e.password,
      };

      try {
        await axios.post(`${BASE_URL2}/auth/verify-otp`, {
          phone: phone,
          otp: e.otp,
        });
        loginRequest(payload);
      } catch (e) {
        // this.setState({ loading: false });
        notification.error({
          message: "Wrong OTP",
          description: "You've entered the wrong otp",
        });
      } finally {
        setLoading(false);
      }
    }
    // alert("hi");
  }

  // console.log(loading);

  return (
    <Fragment>
      <div className="min-h-screen font-nunito  bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src={Logo} alt="Workflow" />
              <h2 className="mt-6 text-2xl font-extrabold  text-gray-600">
                Tap and Go <h2 className="text-red-500">Business Operations</h2>
              </h2>
              <h2 className="mt-6 text-3xl font-bold text-gray-900">
                Sign in to your account
              </h2>
              <p className="mt-2 font-nunito text-sm text-gray-600">
                Or
                <a
                  href="/"
                  className="font-medium text-red-600 hover:text-red-500"
                >
                  {" "}
                  sign up as a business
                </a>
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <Form
                  layout="vertical"
                  onFinish={(e) => handleLogin(e)}
                  className="space-y-6"
                >
                  {!showOtp && (
                    <div>
                      {/* <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Username or Email or Phone
                      </label> */}
                      <div className="mt-1">
                        <Form.Item
                          label="Username"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Username!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            prefix={
                              <UserOutlined className="site-form-item-icon" />
                            }
                            type="username"
                            placeholder="Username"
                          />
                        </Form.Item>
                        {/* <input
                          id="username"
                          name="username"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                        /> */}
                      </div>
                    </div>
                  )}

                  {showOtp && (
                    <div>
                      <div style={{ marginBottom: 10 }}>
                        <div className="mt-1">
                          <Form.Item
                            name={"otp"}
                            label="Enter OTP Below"
                            style={{ paddingBottom: 10 }}
                            help={` An otp has been sent to phone with ******
                            ${phone.substring(6, 10)} or Dial *713*091# to get
                            your otp`}
                          >
                            <Input.OTP
                              style={{ flex: 1 }}
                              name="otp"
                              size="large"
                              length={5}
                            />
                          </Form.Item>
                          <p></p>
                        </div>
                      </div>

                      <Form.Item
                        name="password"
                        help="Enter your account password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                    </div>
                  )}

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember_me"
                        name="remember_me"
                        type="checkbox"
                        className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
                      />
                      <label
                        for="remember_me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>

                  <div>
                    <Button
                      loading={loading || loadingg}
                      type="submit"
                      title={showOtp ? "Verify OTP and Sign In" : "Sign In"}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1607434531924-050d4e675a44?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
            alt=""
          />
        </div>
      </div>
    </Fragment>
  );
}
