import SalesAgent from "../screens/SalesAgents";
import SalesAgentDetails from "../screens/SalesAgents/Details";

const { lazy } = require("react");

const Dashboard = lazy(() => import("../screens/Dashboard/index"));
const Merchants = lazy(() => import("../screens/Merchants/index"));
const MerchantDetails = lazy(() =>
  import("../screens/Merchants/MerchantDetails")
);
const LoyaltyCards = lazy(() => import("../screens/LoyaltyCards/index"));
const MerchantDeposits = lazy(() =>
  import("../screens/MerchantDeposits/index")
);
const Settings = lazy(() => import("../screens/Settings"));
const Transactions = lazy(() => import("../screens/Transactions"));
const Payouts = lazy(() => import("../screens/Payouts"));
const Tills = lazy(() => import("../screens/Tills"));
const Event = lazy(() => import("../screens/Events"));
const Subscriptions = lazy(() =>
  import("../screens/LoyaltyCards/Subscriptions")
);
const BalanceSheets = lazy(() => import("../screens/Balance_Sheet/index"));
const SubscriptionDetails = lazy(() =>
  import("../screens/LoyaltyCards/SubscriptionDetails")
);
const ConductorSummary = lazy(() =>
  import("../screens/IntercityConductorPayments/index")
);

const routes = [
  {
    path: "/dashboard",
    exact: false,
    component: Dashboard,
    name: "Dashboard",
  },
  {
    path: "/merchants",
    exact: true,
    component: Merchants,
    name: "Merchants",
  },
  {
    path: "/merchants/details/:id",
    exact: true,
    component: MerchantDetails,
    name: "MerchantDetails",
  },
  // {
  //   path: "/loyalty-cards",
  //   exact: false,
  //   component: LoyaltyCards,
  //   name: "LoyaltyCards",
  // },

  {
    path: "/balance-sheets",
    exact: false,
    component: BalanceSheets,
    name: "BalanceSheets",
  },
  {
    path: "/merchant-deposits",
    exact: false,
    component: MerchantDeposits,
    name: "MerchantDeposits",
  },
  {
    path: "/settings",
    exact: false,
    component: Settings,
    name: "Settings",
  },
  {
    path: "/subscriptions",
    exact: false,
    component: Subscriptions,
    name: "Subscriptions",
  },
  {
    path: "/subscription/detail/:id/:name",
    exact: true,
    component: SubscriptionDetails,
    name: "Subscriptions",
  },
  {
    path: "/transactions",
    exact: false,
    component: Transactions,
    name: "Transactions",
  },
  {
    path: "/tills",
    exact: false,
    component: Tills,
    name: "Tills",
  },
  {
    path: "/payouts",
    exact: false,
    component: Payouts,
    name: "Payouts",
  },
  {
    path: "/events",
    exact: false,
    component: Event,
    name: "Events",
  },
  {
    path: "/agents",
    exact: true,
    component: SalesAgent,
    name: "SalesAgent",
  },
  {
    path: "/conductor-payments",
    exact: true,
    component: ConductorSummary,
    name: "ConductorPayments",
  },
  {
    path: "/agents/details/:id",
    exact: true,
    component: SalesAgentDetails,
    name: "SalesAgentDetails",
  },
];

export default routes;
