import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import {
  Col,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { toaster } from "evergreen-ui";
import useRequest from "../../utils/Request";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../utils/cookies.config";

export default function SalesAgent() {
  const [showAddCompanySideSheet, setShowAddCompanySideSheet] = useState(false);
  const [username, setUsername] = useState(true);
  const [searching, setSearching] = useState(false);

  const [addCompany, { loading: loadingCompany }] = useRequest(
    { api: 2, path: "/company/newcompany", method: "POST" },
    {
      onCompleted: (d) => {
        setShowAddCompanySideSheet(false);
        getAllSalesAgents();
        message.success("Sales company has been added successfully");
      },
      onError: (e) => {
        message.error(e.response.data);
      },
    }
  );

  const [
    getAllSalesAgents,
    { data: salesAgents, loading: loadingSalesAgents },
  ] = useRequest(
    {
      api: 1,
      path: "/organisation/sales/all",
    },
    {
      onCompleted: (e) => {
        sessionStorage.setItem("agents", JSON.stringify(e.data));
        // console.log(e);
      },
    }
  );

  async function checkUsername(t) {
    try {
      setSearching(true);
      const isPresent = await axios.get(
        BASE_URL + "/auth/user/checkMerchantUsername?username=" + t
      );
      setUsername(isPresent?.data);
      setSearching(false);
    } catch (e) {
      setSearching(false);
      toaster.warning(e.message);
    }
  }

  useEffect(() => {
    getAllSalesAgents();
  }, []);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Sales Agents
          </h2>
          <h4 className="text-gray-500">
            Companies registered as Tap and Go Sales Agents
          </h4>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <Button
            onClick={() => {
              setShowAddCompanySideSheet(true);
            }}
            title="Add Company"
          />
        </div>
      </div>
      <div className="mt-8 flow-root overflow-hidden">
        <div className="bg-white p-5 rounded-md">
          <table className="w-full text-left">
            <thead className="bg-white p-10">
              <tr>
                <th
                  scope="col"
                  className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Role
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {salesAgents?.map((person) => (
                <tr key={person._id}>
                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    {person.name}
                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {person.phonenumber}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {person.email}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {moment(person.createdAt).calendar()}
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <a
                      href={`#/agents/details/${person?._id}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Drawer
        size="large"
        title={<Typography.Title>Add Sales Agency Company</Typography.Title>}
        open={showAddCompanySideSheet}
        onClose={() => {
          setShowAddCompanySideSheet(false);
        }}
        destroyOnClose={true}
      >
        <Form
          layout="vertical"
          onFinish={async (e) => {
            if (username) {
              toaster.danger(
                "Invalid username, please choose another username"
              );
              return;
            }

            const merchant = {
              username: e.username,
              passcode: e.phoneNumber.substring(6, 9),
              firstname: "Main Account",
              lastname: "",
              role: "admin",
            };

            const company = {
              name: e.name,
              email: e?.phoneNumber + "-" + e.email,
              phonenumber: e.phoneNumber,
              password: e.phoneNumber,
              username: e.username,
              type: "sales",
              merchant,
            };

            addCompany(company);
          }}
        >
          <Divider>Company Details</Divider>
          <Form.Item name="name" required label="Company Name">
            <Input required width={"100%"} placeholder="Company name here" />
          </Form.Item>
          <Row gutter={10}>
            <Col flex={1}>
              <Form.Item name="email" required label="Email">
                <Input
                  width={"100%"}
                  required
                  inputMode="email"
                  placeholder="Email of company"
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item name="phoneNumber" required label="Phone Number">
                <Input
                  required
                  width={"100%"}
                  name="phoneNumber"
                  inputMode="numeric"
                  placeholder="Phone number of company"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="username" required label="Username">
            <Input.Search
              onChange={(t) => {
                if (
                  t.target.value
                    .trim()
                    .match(/^(?=[a-z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/)
                ) {
                  checkUsername(t.target.value);
                } else {
                  setUsername(true);
                }
              }}
              required
              width={"100%"}
              placeholder="Enter your username here"
            />
          </Form.Item>
          {searching ? (
            <p>Loading...</p>
          ) : username ? (
            <p className="text-sm text-red-500">Username not available</p>
          ) : (
            <p className="text-sm text-green-500">Username available</p>
          )}

          <Form.Item>
            <Button loading={loadingCompany} htmlFor="submit" title="Submit" />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
