import React, { Suspense, Fragment, useContext, useEffect } from "react";
import { TopBarLoader } from "../../components/Loaders";
import PrivateRoute from "../../navigation/PrivateRoute";
import routes from "../../navigation/routes";
import { PartnerContext } from "../../utils/UserContext";
import AppHeader from "./AppHeader";
import { useHistory, useLocation } from "react-router-dom";
import Auth from "../../utils/cookies.config";

export default function Layout() {
  const { push } = useHistory();

  const user = useContext(PartnerContext);

  useEffect(() => {
    let token = Auth.getCipher();

    if (!token) {
      push("/login");
    }
  }, []);


  const { pathname } = useLocation();

  return (
    <Fragment>
      <div className="font-nunito">
        <AppHeader />
        <div
          className={
            "min-h-screen max-h-full font-nunito overscroll-y-auto max-w-full bg-gray-100"
          }
        >
          <main
            className={`${
              !pathname.includes("events") && "max-w-8xl"
            }  mx-auto ${
              !pathname.includes("events") && "py-5 sm:px-4 lg:px-8"
            }  `}
          >
            <Suspense fallback={TopBarLoader()}>
              {routes.map((screen, i) => (
                <PrivateRoute
                  name={screen.name}
                  component={screen.component}
                  exact={screen.exact}
                  path={screen.path}
                  key={i}
                />
              ))}
            </Suspense>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
