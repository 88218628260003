import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function Button(props) {
  return (
    <button
      disabled={props?.loading}
      {...props}
      class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none "
    >
      {props?.loading === true ? (
        <ClipLoader color="white" size={20} />
      ) : (
        props?.title
      )}
    </button>
  );
}
